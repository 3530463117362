// Testing 
//export const SITE_URL = "https://test.scribex.in/"
//export const SERVICE_DOMAIN = "https://service.scribex.in/SpeechAuthService/"
//export const USERCONSOLE_DOMAIN = "https://shop.scribex.in/"

// Production 
export const SITE_URL = "https://augnito.ai/"
export const SERVICE_DOMAIN = "https://service.augnito.ai/SpeechAuthService/"
export const USERCONSOLE_DOMAIN = "https://shop.augnito.ai/"

export const FETCH_REGION_INFO = SERVICE_DOMAIN + "/api/v1/DataResource/fetchregioninfo"
export const PRODUCT_PLAN_DETAILS = SERVICE_DOMAIN + "/api/v2/ProductPlanPrice/productplandetails"
export const DEFAULT_REGION = "US"
export const DEFAULT_COUNTRY = "India"
export const SPECTRA_LISTING_NAME = "augnito-spectra"
export const VOICE_LISTING_NAME = "augnito-voice-services"
export const MAYA_LISTING_NAME = "augnito-maya"
export const ULTRA_LISTING_NAME = "augnito-ultra"


