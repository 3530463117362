import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import PrivacyPolicyMeta from './PrivacyPolicyMeta';

export default class PrivacyPolicy extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <PrivacyPolicyMeta/>
                <section className='aug-legal-page mt-60'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                                <div className='aug-legal-page-header'>
                                    <h1 className='aug-legal-page-title mb-3'>Augnito Privacy Policy</h1>
                                    <p className='mb-0 text-secondary'>Last updated: 29th June, 2023</p>
                                </div>
                                <div className='mb-4'>
                                    <p className='mb-3'>
                                        Augnito India Private Limited having its registered office at 31B, Floor – 1, Plot – 15, Meher House, Cawasji Patel Road, Horniman Circle Fort Mumbai, Mumbai City, MH 400001 India, its affiliates and subsidiaries (“<strong>our</strong>”, “<strong>we</strong>”, and “<strong>us</strong>”) respect your privacy, and we are committed to protecting it in accordance with the terms of this privacy policy (the “<strong>Policy</strong>”) when a natural person accesses, downloads, browses or uses our Platform (defined hereinafter) and/ or any legal entity which avails, accesses or uses our cloud-hosted services that converts voice to text (“<strong>Services</strong>”) through its employee, member, representative, agent, or service provider (“<strong>Personnel</strong>”) (collectively with a natural person referred to as “<strong>user</strong>”, “<strong>you</strong>”, “<strong>your</strong>”).
                                    </p>
                                    <p className='mb-0'>
                                        This Policy describes the types of information we may collect from User/you or that you may provide when you visit our website, which is accessible at <a rel="noreferrer" target="_blank" href="https://augnito.ai/"><u>https://augnito.ai/</u></a> (the “<strong>Website</strong>”), download and access our mobile application (the “<strong>App</strong>”), access and use Augnito via our platform (the “<strong>Platform</strong>”) or establish an account with us (the “<strong>Account</strong>”), and our practices for collecting, using, maintaining, protecting, and disclosing that information.
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>1. Territorial Scope</h3>
                                    <p className='mb-0'><strong>1.1</strong>
                                        The Website, App, Platform, and Augnito are available for use in India and rest of the world. In addition to the following clauses some additional clauses apply to North America and South America (USA), Middle East except Saudi Arabia (UAE), Kingdom Of Saudi Arabia (KSA), these clauses provided at the end of this Policy
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>2. General</h3>
                                    <p className='mb-3'><strong>2.1 </strong> 
                                    This Policy, together with the General Terms of Use (available here: <a rel="noreferrer" href="https://augnito.ai/legal/terms-of-use" target="_blank"><u>Terms &amp; Conditions</u></a>) governs your use of our Platform and/ or Services; describes the types of information we may collect from you or that you may provide when you (a) visit, browse, access or use our website, which is accessible at <a target="_blank" rel="noreferrer" href="https://augnito.ai/"><u>https://augnito.ai/</u></a>, (b) download, browse, access or use our mobile application (collectively, the “Platform”), (c) use or access the Services and our associated services, or (d) register an account with us (the “Account”), and lays down our practices and purposes for collecting, receiving, processing, storing, dealing, using, maintaining, transferring, and disclosing such information.</p>
                                    <div className='mb-3'>
                                        <p className='mb-0'> <strong>2.2 </strong> This Policy applies to information we collect or receive:</p>
                                        <p className='mb-0'>(a) On our Platform;</p>
                                        <p className='mb-0'>(b) Via e-mail and other electronic communication between you and us; or</p>
                                        <p className='mb-0'>(c) When you use Services or create an Account as a Personnel of a commercial enterprise or organization, etc.</p>
                                        <p className='mb-0'>This Policy does not apply to information collected by:</p>
                                        <p className='mb-0'>(a) us in physical form and not in a computer resource; or</p>
                                        <p className='mb-0'>(b) any third party, including through any website, application or content (including advertising) that may link to or be accessible from our Platform.</p>
                                    </div>
                                    <p className='mb-3'><strong>2.3 </strong> 
                                        Please read this Policy carefully to understand our policies and practices regarding your information collected, stored and handled by us and how we will treat it. By accessing our Platform, and/or by using Services, you agree to and accept the practices described in this Policy. If you are using the Platform and/ or Services, you represent that you have the authority to act on behalf of such enterprise or organization, and that such entity accepts this Policy. If you do not agree with the terms of this policy, please do not access the Platform and/or Services and/or create an Account.
                                    </p>
                                    <p className='mb-0'><strong>2.4 </strong> 
                                        If you are using, accessing, browsing our Platform and/ or Services or allowing the use, access or browsing of the Platform and/ or Services by your Personnel for commercial purposes under any arrangement or agreement with us, you accept sole responsibility for any Information (defined below) collected or received by you or your Personnel from your customers or clients (“User Data”). You undertake that the User Data provided to us by you has been received or collected by lawful means with the consent of the Users(customer) and clients, and does not constitute a violation of data protection laws. You agree and acknowledge that you shall be solely liable if you or your Personnel violate any provision or term of this Policy. We, our employees, agents, directors, partners and officers will not be liable for any loss or direct or indirect damage arising from your or your Personnel’s failure to comply with this Policy. You agree and understand that you may be held liable for any losses incurred to us or our business partners or our customers or any other individual due to your violation of this Policy or any unauthorized use of the Platform and/ or Services by you or your Personnel. We reserve the right to refuse access to the Platform and/ or Services, terminate Accounts, remove or edit content at any time without notice to you if you violate the terms of this Policy.
                                    </p>
                                    
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>3. Information We Collect About You</h3>
                                    <div className='mb-3'>
                                        <p className='mb-3'><strong>3.1 </strong>
                                            We may collect or receive Personal Information (defined below) and sensitive personal data or information (“<strong>SPDI</strong>,” together with Personal Information, “<strong>Information</strong>”) voluntarily given by you with your consent when you access the Platform and/ or use Services and/ or by a commercial enterprise or organization when it provides User Data. We store User Data in an anonymous and aggregate form such that it is incapable of personally identifying any individual. For the purposes of this Policy, “<strong>Personal Information</strong>” means the information (whether individually or in combination with other information available or likely to be available with us) that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to an individual. We may combine non-personal information with Personal Information, and in those circumstances, we will treat the combined information as Personal Information. We may collect or receive several types of Personal Information from and about you when you use our Platform, Services and/or when you create an Account, including the following information:
                                        </p>
                                        <ul class="list-unstyled ps-0 mb-0">
                                            <li><strong>(a)</strong> First and Last Name,</li>
                                            <li><strong>(b)</strong> Postal Address,</li>
                                            <li><strong>(c)</strong> Email Address,</li>
                                            <li><strong>(d)</strong> Phone Number,</li>
                                            <li><strong>(e)</strong> Gender, </li>
                                            <li><strong>(f)</strong> Nationality, </li>
                                            <li><strong>(g)</strong> Internet/Network information, such as device name, device ID, browsing history, browser type, interaction with Platform, or online advertisements on the Platform, or</li>
                                            <li class="mb-0"><strong>(h)</strong> Inquiry Information, such as your questions/feedback to us or survey reslionses. </li>
                                        </ul>
                                    </div>
                                    <div className='mb-3'>
                                        <p className='mb-0'><strong>3.2 </strong>
                                            We may also collect, receive, process or store certain SPDI consisting of, but not limited to:     
                                        </p>
                                        <ul class="list-unstyled ps-0 mb-0">
                                            <li><strong>(a)</strong> Password,</li>
                                            <li><strong>(b)</strong> Biometric Information,</li>
                                            <li><strong>(c)</strong> Sexual Orientation,</li>
                                            <li><strong>(d)</strong> Physical, physiological and mental health condition,</li>
                                            <li><strong>(e)</strong> Medical records and history, or </li>
                                            <li className='mb-0'><strong>(f)</strong> Any of the information received under above clauses by us for providing services and processing, storing or processing under lawful contract or otherwise. </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>4. How We Collect Your Information</h3>
                                    <p className='mb-3'><strong>4.1. We collect your Information from various sources that may include, for example, the following:</strong>   
                                    </p>
                                    <p className='mb-3'><strong>(a) You. </strong> 
                                        Information you provide directly when you use or otherwise interact with the Platform and/or Products; and/or register an online Account, including User Data.  
                                    </p>
                                    <p className='mb-3'><strong>(b) Your Browser or Device. </strong> 
                                        Information collected automatically as a result of your interaction with us and your use of Products. The information that we automatically collect, include your IP Address, geolocation information (which may include specific longitude and latitude), time zone, usage times and volume, and information about the device you are using (for example, device type, device ID, characteristics and status, browser type, operating system and application version and information about how our Platform is used), language preference, URL click stream, or information that we collect through our marketing activities. We use : Google Analytics ,Google Ads, Facebook Pixel ,Linkedin, HubSpot and Twitter to collect such data. Terms of Service for each of these are available om their respective websites. We also use cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Platform. Unless, you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Platform. 
                                    </p>
                                    <p className='mb-3'><strong>(c) Third Parties. </strong> 
                                        Information we collect about you from third parties or from our service providers including Google Analytics ,Google Ads, Facebook Pixel ,Linkedin, HubSpot and Twitter and partners.  
                                    </p>
                                    <p className='mb-3'><strong>(d) Otherwise, in accordance with our agreements with you. </strong> 
                                        Information as described elsewhere in this Policy, any applicable terms, or at the time such information is collected. 
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>5. How We Use Your Information</h3>
                                    <p className='mb-3'><strong>5.1. We use information that we collect about you or that you provide to us, including any Personal Information or SPDI:</strong>   
                                    </p>
                                    <p className='mb-3'><strong>(a) </strong> 
                                        To provide you with access to Services, our Platform and Account. 
                                    </p>
                                    <p className='mb-3'><strong>(b) </strong> 
                                        To provide you with information, products or services that you request from us from time to time.
                                    </p>
                                    <p className='mb-3'><strong>(c) </strong> 
                                        To notify you about changes to our Platform, or Services, or any additional services that we offer or provide though it. 
                                    </p>
                                    <p className='mb-3'><strong>(d) </strong> 
                                        To improve your experience. 
                                    </p>
                                    <p className='mb-3'><strong>(e) </strong> 
                                        To provide customer support. 
                                    </p>
                                    <p className='mb-3'><strong>(f) </strong> 
                                        For internal recordkeeping and analytical purposes including data analysis, auditing and research. 
                                    </p>
                                    <p className='mb-3'><strong>(g) </strong> 
                                        To detect, monitor, investigate, deter, and protect against fraudulent, unauthorized or illegal activity. 
                                    </p>
                                    <p className='mb-3'><strong>(h) </strong> 
                                        To inform you about promotions, events, updates, offers and news related to the Platform or Services. 
                                    </p>
                                    <p className='mb-3'><strong>(i) </strong> 
                                        In any other way we may describe when you provide the information. 
                                    </p>
                                    <p className='mb-3'><strong>(j) </strong> 
                                        Contact you with respect to the Platform or Services. 
                                    </p>
                                    <p className='mb-3'><strong>(k) </strong> 
                                        To fulfill any other purpose for which you provide your information. 
                                    </p>
                                    <p className='mb-3'><strong>(l) </strong> 
                                    To comply with any legal or regulatory requirement or comply with a request from any governmental or judicial authority. 
                                    </p>
                                    <p className='mb-3'><strong>(m) </strong> 
                                    To resolve any request, dispute, grievance or complaint raised by you in relation to your use of the Platform or Services. 
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>6. Disclosure of Your Information</h3>
                                    <p className='mb-3'><strong>6.1. </strong>               
                                    We may from time to time, disclose the aggregated, anonymous and non-personal User Data stored by us which does not identify any individual, with any third party, service providers, affiliates for, inter alia, commercial, research, statistical analysis and business intelligence purposes.
                                    </p>
                                    <p className='mb-3'><strong>6.2. </strong> 
                                    Subject to Paragraph 5.1 above, by using, accessing or browsing our Platform and/ or Services you consent to the disclosure of your Information by us, in accordance with the terms of this Policy. We may disclose Information that we collect, receive or you provide as described in this Policy:
                                    </p>
                                    <p className='mb-3'><strong>(a) </strong> 
                                    To our employees on a need-to-know basis.
                                    </p>
                                    <p className='mb-3'><strong>(b) </strong> 
                                    To our subsidiaries and affiliates on a need-to-know basis.
                                    </p>
                                    <p className='mb-3'><strong>(c) </strong> 
                                    To our contractors, service providers and other third parties, such as our payment processing partners who we use to support our Platform and/ or Services. We ensure that such third parties and service providers bound by contractual obligations to keep your Information confidential and use it only for the purposes for which we disclose it to them 
                                    </p>
                                    <p className='mb-3'><strong>(d) </strong> 
                                    To a buyer or successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which information held by us about the Platform and/ or Services, including Information of our customers and authorized users are among the assets transferred.
                                    </p>
                                    <p className='mb-3'><strong>(e) </strong> 
                                    For any other purpose disclosed by us when you provide the information. 
                                    </p>
                                    <p className='mb-3'><strong>(f) </strong> 
                                    To comply with any court order, law or legal process, including responding to any government or regulatory request or authorized agency.
                                    </p>
                                    <p className='mb-3'><strong>(g) </strong> 
                                    To enforce or apply other agreements, including for billing and collection purposes.
                                    </p>
                                    <p className='mb-3'><strong>(h) </strong> 
                                    If we believe disclosure is necessary or appropriate to protect our rights, property, or safety, or the rights, property, or safety of our customers or others.
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>7. Choices About How We Use and Disclose Your Information</h3>
                                    <p className='mb-3'><strong>7.1. </strong>               
                                    We strive to provide you with choices regarding the information you provide to us. We have created mechanisms to provide you with the following control over your information:
                                    </p>
                                    <p className='mb-3'><strong>(a) Do Not Track: </strong> 
                                        Do Not Track is a privacy preference that can be set in a web browser. The Platform recognizes do not track browser setting or signals. For information about do not track, please visit <a rel="noreferrer" target="_blank" href="https://allaboutdnt.com/"><u>https://allaboutdnt.com/</u></a>.
                                    </p>
                                    <p className='mb-3'><strong>(b) Withdrawing Consent: </strong> 
                                    You may at any-time withdraw your consent for collection and use of your information including Personal Information or SPDI. However, please note that if you withdraw your consent, we may no longer be able to provide you with the corresponding service for which you have withdrawn your consent. It is hereby clarified that your decision to withdraw your consent will not affect the processing of Information based on your previous consent prior to the withdrawal and a separate request for removal will be required in such cases. If you wish to withdraw your consent under this Policy or remove your Information possessed or stored by us, you can contact us at <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a> . We reserve the right to limit membership, services or access to your Account and our Platform and/ or Services based on such request. You will be notified by us prior to any actions taken in this regard.
                                    </p>
                                    <p className='mb-0'><strong>(c) Transfer of Your Information to Third-Party:</strong> 
                                    We do not sell, trade, or otherwise transfer your Information unless we seek your prior consent. However, this does not include Platform hosting partners, service providers and other third parties who assist us in operating our Platform, conducting our business, or providing us services in relation to Services, whether located within India or abroad, subject to such entities agreeing to keep your Information confidential and ensuring the same level of data protection that is adhered to by us under this Policy and as per applicable law. By using our Platform and/or Services, you consent to such transfer of Information to inter alia Platform hosting partners, service providers and other third parties. You agree and understand that we do not control third parties’ collection or use of your information to serve interest-based advertising. You shall not hold us liable for any collection or use of your information by such third parties to serve internet-based advertisements or for other purposes.
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>8. Third Party Links on Our Platform</h3>
                                    <p className='mb-3'><strong>8.1. </strong>               
                                    The Platform and/ or Services may contain links to other third-party websites for your convenience. You agree and understand that we do not exercise control over third party websites displayed as search results or links on the Platform and/ or Services. These other sites may place their own cookies or other files on your computer, collect data or solicit personal information from you, on which we have no control and shall not be held responsible or liable. We do not make any representations concerning the privacy practices or policies of such third parties or terms of use of such websites, nor do we guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials available on such websites.
                                    </p>
                                    <p className='mb-0'><strong>8.2. </strong>               
                                    You agree and understand the inclusion or exclusion of such third-party links does not imply any endorsement by us of such websites, the websites’ provider, or the information on the website. Once you leave the Platform and/ or Services or are redirected to a third-party website, application, or other online service, we encourage you to read the privacy policy applicable to that third party.
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>9. Opt-Out of Communications with Us</h3>
                                    <p className='mb-0'><strong>9.1. E-mail Contact </strong>               
                                    You may opt-out of receiving promotional e-mail communication from us by sending us an e-mail at <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a>, or by clicking the “unsubscribe” button at the bottom of our emails that we send to you. Please note that you will not be able to opt-out of transactional e-mail communication, such as communication related to your Account activities.
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>10. Deleting your Account</h3>
                                    <p className='mb-0'>	You may delete your Account by emailing us at <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a>. By deleting your Account, you will not have access to the Products or Subscription availed.</p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>11. Data Processing and Security</h3>
                                    <p className='mb-3'><strong>11.1. </strong>               
                                    You agree and understand that we rely on third party experts and service providers to ensure the safety and security of your information. We also implement and ensure that our partners implement internal security measures designed to secure your Information from accidental loss and from unauthorized access, use, alteration and disclosure. All Information you provide to us is stored on secure servers behind firewalls. When you submit your Information to us, your Information is protected through our security systems commensurate with respect to the Information being collected and the nature of our business. Please note that we use international and industry-recognized standards to protect your Information and actively pass relevant security and privacy protection certifications.
                                    </p>
                                    <p className='mb-3'><strong>11.2. </strong>               
                                    The safety and security of your Information also depends on you. You shall be solely responsible for keeping the Account credentials, including usernames or password, to access or use Services confidential and prevent any unauthorized access of the Account by your members, personnel, customers or clients. We ask you not to share your Account credentials with anyone. You alone are responsible for your actions or the actions of any person using your Account username and/or password. As a commercial enterprise or organization using or providing access to Services to its members, employees or personnel, you shall be solely responsible for any actions or omissions by your members, employees or personnel of this Policy. In this regard, you shall indemnify and hold us and our officers, directors, employees, affiliates, agents, licensors, and business partners harmless from and against any and all loss, costs, damages, liabilities, and expenses (including attorneys' fees) incurred in relation to, arising from, or for the purpose of avoiding, any claim or demand from a third party that your or your member, employees or personnel’s use of the Platform and / or Services or the Account (including without limitation your participation in the posting areas or your submissions to us) violates the Policy, any applicable law or regulation, or the rights of any third party. In the event we are made aware of any unauthorised or illegal use of the Platform and/ or Services or breach of this Policy, you shall fully cooperate with us to investigate and remedy such use or breach, as applicable.
                                    </p>
                                    <p className='mb-3'><strong>11.3. </strong>               
                                    Although we make best possible efforts to transmit and store all the Information provided by you by encrypting data in transit or in a secure operating environment that is not open to public, you understand and acknowledge that there is no such thing as complete security and we do not guarantee that there will be no unintended disclosures of any information and potential security breaches. You agree not to hold us responsible for any breach of security or for any action of any third parties that receive your Information or events that are beyond our reasonable control including, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, etc. Any transmission of Information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on our Platform or Services.
                                    </p>
                                    <p className='mb-0'><strong>11.4. </strong>               
                                    Information that you provide is hosted on dedicated servers of our third-party partners.
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>12. Changes to Our Privacy Policy</h3>
                                    <p className='mb-0'>We may need to modify the terms of this Policy from time to time and we encourage you to review this Policy regularly. Any changes or modifications will be effective immediately upon posting the revisions on the Platform and you will be bound by such revised/ modified terms of the Policy. If material changes to this Policy are made, we will notify you by posting a notice on the Platform or by sending you notice by email to the address registered/ associated with your Account. We will also indicate at the top of this page when this Policy was last updated. Your continued use of the Platform or Services means that you accept all modified terms of the Policy and consent to the use of your Information in accordance with such updated Policy.</p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>13. Retention of Information</h3>
                                    <p className='mb-0'> We will retain your information and any data for the period necessary to fulfil the purposes outlined in this Policy unless a longer retention period is required or permitted under the applicable law. We do not retain User Data beyond 90 (ninety) days. This does not include , the user id and details required for creating and maintaining login information. The use of this data is limited to the purpose of providing services and sending updates & promotional emails. However, the user can request for deletion/rectification of the their personal data by mailing on <a rel="noreferrer" target="_blank" href="mailto:legal@augnitoai"> <u>legal@augnito.ai</u> </a> or can contact the Data Protection Officer <strong>Aakanksha Yadav</strong>  at  <a rel="noreferrer" target="_blank" href="mailto: aakanksha.yadav@augnito.ai"><u>aakanksha.yadav@augnito.ai</u></a> For any further information they need in this regard.</p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>14. Governing Law</h3>
                                    <p className='mb-0'>The terms of this Policy shall be governed and construed in accordance with the laws of India. Any dispute regarding or arising out of this Policy shall be subject to the exclusive jurisdiction of the courts in Mumbai, Maharashtra. We would herein like to mention that we are GDPR ,IS027001 and Hipaa complaint.</p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>15. Grievance Redressal</h3>
                                    <p className='mb-3'>In case, you have any questions, grievance or complaints about this Policy, or the Platform, you may contact our grievance officer on the below mentioned details:</p>
                                    <p className='mb-0'><strong>Name: </strong> Aakanksha Yadav</p>
                                    <p className='mb-0'><strong>Email Address:  </strong> <a rel="noreferrer" target="_blank" href="mailto:aakanksha.yadav@augnito.ai"><u>aakanksha.yadav@augnito.ai</u></a> </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>16. Following additional terms are applicable if you are User from region of North America and South America (USA):-</h3>
                                    <p className='mb-3'><strong>16.1. </strong> California Privacy Rights</p>
                                    <p className='mb-3'><strong>16.2.  </strong> We do not sell, trade, or otherwise transfer to outside third parties your Personal Information. California Civil Code Section § 1798.83 permits users of the Website, App, Platform, and/or Augnito that are California residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. To make such a request, please send an e-mail to <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a>. </p>
                                    <p className='mb-3'><strong>16.3.  </strong> Further, the California Consumer Privacy Act of 2018, as amended (Cal. Civ. Code §§ 1798.100 to 1798.199) (“CCPA”), does not apply to us as a “business,” as defined in the CCPA. If you have any questions or concerns about the CCPA or its non-applicability to us as a business, please do not hesitate to e-mail us at <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a>. </p>
                                    <p className='mb-0'><strong>16.4.  </strong> Users can reach out to us for information, rectification, or deletion of Personal Information at <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a>. </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>17. The following additional terms are applicable if you are User from Middle East except Kingdom of Saudi Arabia</h3>
                                    <p className='mb-3'><strong>17.1. </strong> Rights of Users from Middle East except Kingdom of Saudi Arabia</p>
                                    <p className='mb-3'><strong>17.2. </strong> We will store or transfer your personal data in the United Arab Emirates and India. The security of your personal data is essential to us, and to protect your data, we take a number of important measures, including data encryption to meet applicable legal requirements. </p>
                                    <p className='mb-3'><strong>17.3. </strong> We will not share any of your personal data with any third parties for any purposes, other than in some limited circumstances where we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority </p>
                                    <p className='mb-0'><strong>17.4. </strong> To contact us about anything to do with your personal data and data protection, please contact us by email to: <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a> </p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>18. The following additional terms are applicable if you are User from Kingdom of Saudi Arabia (KSA)</h3>
                                    <p className='mb-3'><strong>18.1. </strong> Rights of User from Kingdom of Saudi Arabia (KSA)</p>
                                    <p className='mb-3'><strong>18.2. </strong> Under the GDPR and PDPL you have the following rights, which we will always work to uphold: </p>
                                    <p className='mb-3'><strong>(a) </strong> 	The right to be informed about our collection, use and purpose of your personal data. The Privacy Policy should tell you everything you need to know, but you can always contact us to find out more or to ask any questions at <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a> </p>
                                    <p className='mb-3'><strong>(b) </strong> 	 The right to access the personal data we hold about you. If you want to know what personal data free of charge that we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a “subject access request”. All subject access requests should be made in writing and sent to the email of our Data Protection Officer (details mentioned at the end) </p>
                                    <p className='mb-3'><strong>(c) </strong> The right to have your personal data rectified, complete or update if any of your personal data held by us is inaccurate or incomplete. Please contact our Data Protection Officer</p>
                                    <p className='mb-3'><strong>(d) </strong> The right to be forgotten, i.e., the right to ask us to delete or otherwise dispose of any of your personal data that we hold, please contact our Data Protection Officer</p>
                                    <p className='mb-3'><strong>(e) </strong> The right to restrict (i.e., prevent) the processing of your personal data.</p>
                                    <p className='mb-3'><strong>(f) </strong> The right to object to us using your personal data for a particular purpose or purposes.</p>
                                    <p className='mb-3'><strong>(g) </strong> The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.</p>
                                    <p className='mb-3'><strong>(h) </strong> Rights relating to automated decision-making and profiling. We do not use your personal data in this way.</p>
                                    <div className='mb-3'>
                                        <p className='mb-0'><strong>18.3. </strong> more information about our use of your personal data or exercising your rights as outlined above, please contact the following</p>
                                        <p className='mb-0'><strong>Data Protection Officer, Name: </strong> Aakanksha Yadav</p>
                                        <p className='mb-0'><strong>Email: </strong> <a rel="noreferrer" target="_blank" href="mailto: aakanksha.yadav@augnito.ai"><u>aakanksha.yadav@augnito.ai</u></a></p>
                                    </div>
                                    <p className='mb-3'><strong>18.4. </strong> Further information about your rights can also be obtained from the Information Commissioner’s Office or your local Citizens Advice Bureau.</p>
                                    <p className='mb-0'><strong>18.5. </strong> If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner’s Office.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}